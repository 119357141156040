.card1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 40px 0;
}

.card1-card {
    position: relative;
    min-width: 320px;
    height: 440px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    margin: 30px;
    transition: 0.5s;
    /* box-shadow: inset 5px 5px 5px #344f6a,
        inset -5px -5px 15px #344f6a,
        5px 5px 15px #344f6a, -5px -5px 15px #344f6a; */
}

.card1-card:nth-child(1) .card1-box .card1-content a {
    background: #2196f3;
}

.card1-container .card1-card:nth-child(2) .card1-box .card1-content a {
    background: #e91e63;
}

.card1-container .card1-card:nth-child(3) .card1-box .card1-content a {
    background: #23c186;
}

.card1-container .card1-card .card1-box {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    /* background: #6f85db; */
    filter: grayscale(0.2);
    /* background-image: url('https://res.cloudinary.com/doiocpcni/image/upload/v1704727595/Rmageddon2K24/DSC_1017_bqqanw.jpg'); */
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
}

.card1-container .card1-card .card1-box:hover {
    transform: translateY(-50px);
}

.card1-container .card1-card .card1-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
}

.card1-container .card1-card .card1-box .card1-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.707); */
    width: 100%;
    height: 100%;
}

.card1-container .card1-card .card1-box .card1-content h2 {
    position: absolute;
    top: -10px;
    right: 30px;
    font-size: 8rem;
    color: rgba(255, 255, 255, 0.1);
}

.card1-container .card1-card .card1-box .card1-content h3 {
    font-size: 1.8rem;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 15px;
    width: 95%;
    text-align: center;
    color: #95c5f4;
    font-family: myFont;
}

.card1-container .card1-card .card1-box .card1-content p {
    font-size: 1rem;
    font-weight: 300;
    color: white;
    z-index: 1;
    transition: 0.5s;
    width: 95%;
    text-align: justify;
    display: none;
}

.card1-container .card1-card .card1-box .card1-content a {
    width: 100px;
    position: relative;
    /* display: inline-block; */
    padding: 8px 20px;
    background: #262626;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    margin: 5px 0px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card1-container .card1-card .card1-box .card1-content a:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    background: #fff;
    color: #000;
}

.card1-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#card1-1 {
    background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947673/iemhyuxhfe6qanldrmiv.jpg");
}

#card1-2 {
    background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947660/h6rn3y603dfmhgcqivxh.jpg");
}

#card1-3 {
    background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/j0hssf3gl0j9xuskebjc.png");
}

#card1-4 {
    background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947667/cnsbo99wpncg2xf2fzku.jpg");
}

.card1-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}