.heropagemain {
    scroll-snap-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heropage {
    margin: 50px 0px;
    width: 100%;
    height: 100%;
}

.relativecontent {
    position: relative;
}

@media (max-width : 1200px) {

    .heropagemain {
        height: auto;
    }

    .heropage {
        margin: 0;
        width: 100%;
        height: auto;
        background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947670/gswqsse345irb8qhvu8z.png");
        background-repeat: no-repeat;
        background-size: 100% 125%;
    }

    .main {
        height: auto;
    }
}