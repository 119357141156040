.coursel-g {
    width: 80%;
    height: 100%;
    max-height: 300px;
}

.coursel-g div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coursel-g div img  {
    width: 100%;
    max-width: 300px;
}