@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

@font-face {
    font-family: myFont ;
    src: url("./fonts/batmfa.ttf");
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-color: #111111;
    color: white;
    overflow-x: hidden;
}

#background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -999;
}

@media (max-width : 1200px) {
    #background {
        display: none;
    }
}

.gradient {
    position: fixed;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    z-index: -9999;
    background-attachment: fixed;
}

@media ( max-width : 1200px ) {
    .gradient {
        display: none;
    }
}

#gradient1 {
    box-shadow: 0px 0px 300px 100px #8C3578;
    right: 0;
    top: 30%;
}

#gradient2 {
    box-shadow: 0px 0px 300px 100px #95c5f4;
    left: 0;
    top: 0;
}

#gradient3 {
    box-shadow: 0px 0px 300px 100px #95c5f4;
    left: 30%;
    bottom: 0;
}

.vivek {
    width: 100vw;
    height: 100vh;
}

.main {
    width: 100vw;
    height: 100vh;
    position: relative;
}

button:active {
    scale: 0.95;
}

button:hover {
    background-color: white;
    color: #111111;
    scale: 1.05;
}

.homepage {
    margin-left: 20px;
}
