.news {
  position: absolute;
  display: flex;
  width: 100%;
  height: 50px;
  z-index: 10000000000;
  background-color: #95c5f4;
  align-items: center;
  justify-content: center;
}

.news-in {
  display: flex;
  width: 85%;
  height: 50px;
  z-index: 1;
  /* background-color: aqua; */
  justify-content: space-between;
}

.news h4 {
  font-size: 18px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #111111;
  font-family: "Poppins";
}

.news span {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px 0 0 0;
}

.news-cancel {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.news-cancel button {
  cursor: pointer;
}

#Know {
  height: 30px;
  width: 90px;
  /* border: 2px solid white; */
  border: none;
  text-decoration: underline;
  /* border-radius: 30px; */
  background-color: transparent;
  color: #111111;
  font-family: "Poppins", Roboto;

}

#close {
  border: none;
  background: transparent;
}

@media screen and (max-width: 768px) {


  .news h4 {
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) {


  .news-in {

    width: 95%;
    ;
  }
}