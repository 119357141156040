.loader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader video {
    width: 20%;
}

@media (max-width : 600px ) {
    .loader video {
        width: 30%;
    }
}