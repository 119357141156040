.blackdiv {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blackdiv-heading1 {
    width: 90%;
    font-size: 2rem;
    font-family: myFont, 'Wallpoet';
    margin-top: 25px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width : 1200px) {
    .blackdiv {
        align-items: center;
    }
    .blackdiv-heading1 {
        width: 100%;
        text-align: center;
    }
}

