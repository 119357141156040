.accommodation-main-div {
    width: 100vw;
    /* height: 100vh; */
    background-color: #111111;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.accom-div {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accom-div p {
    max-width: 800px;
    width: 95%;
    text-align: center;
    color: white;
    font-family: "Poppins";
    font-size: 2.2rem;
    margin: 0;
}

.accom-div a {
    color: white;
}

@media (max-width : 1200px) {
    .accom-div p {
        font-size: 1.5rem;
    }
}