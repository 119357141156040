.unlock-parentdiv {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unlock-maindiv {
    width: 75%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.unlock-text {
    width: 63%;
    font-family: myFont, 'Wallpoet', sans-serif;
    font-size: 2rem;
    margin-bottom: 0px;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.unlock-text-div {
    width: 100%;
}

.unlock-content {
    width: 80%;
    padding: 0;
}

.unlock-content li {
    margin: 15px 0px;
    color: white;
    font-family: 'Poppins', 'JetBrains Mono', sans-serif;
    font-size: 1rem;
    text-align: justify;
}

.award {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.cash-prize,
.certificate {
    width: 30%;
    height: 50%;
    min-width: 400px;
    border: 2px solid #99C7F4;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 2px #99C7F4;
}

.unlock-heading {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    font-family: 'Gugi', 'Wallpoet';
}

.award-p {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1rem;
    font-family: 'Poppins', 'JetBrains Mono', sans-serif;
}

.cash-prize:hover, .certificate:hover {
    scale: 1.1;
}

@media (max-width : 1200px) {
    .unlock-parentdiv {
        height: auto;
    }
    .unlock-maindiv {
        width: 100%;
        height: auto;
        background-color: #111111;
    }
    .unlock-text {
        width: 100%;
        text-align: center;
        padding: 25px 0px;
    }
    .unlock-content {
        width: 80%;
        max-width: 500px;
    }
    .award {
        height: auto;
        flex-direction: column;
    }
    .cash-prize, .certificate {
        margin-top: 50px;
    }
    .certificate {
        margin-bottom: 50px;
    }
}

@media (max-width : 500px) {
    .cash-prize, .certificate {
        width: 80%;
        min-width: 0px;
    }
}