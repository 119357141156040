.Main {
    scroll-snap-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Box{
    width: 100%;
    height: 90%;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.spon-info-div {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.spon-first-p {
    width: 63%;
    font-size: 2rem;
    font-family: myFont;
    text-align: left;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
}

.spon-swag {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swag-logo {
    width: 15%;
    height: 200px;
    background-image: url("./swaglogo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.swag-logo img {
    width: 100%;
}

@media (max-width : 1200px) {
    .spon-swag{
        flex-direction: column;
    }
    .swag-logo {
        width: 80%;
    }
}

.spon-sec-p {
    width: 80%;
    font-size: 0.9rem;
    color: white;
    text-align: justify;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
}

.span1 {
    font-size: 1.5rem;
    font-family: "Gugi";
    margin: 10px 0;
}

.span2 {
    font-size: 1rem;
    font-weight: 700;
    text-decoration: underline;
}

.span3 {
    font-size: 1rem;
}

.span4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media ( max-width : 1200px ) {
    .span4 {
        flex-direction: column;
        height: 200px;
    }
    .spon-info-div button {
        margin: 50px 0;
    }
}

.spon-info-div button {
    width: 160px;
    height: 40px;
    background-color: #95c5f4;
    border: none;
    color: #262626;
    font-size: 1rem;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    clip-path: polygon(0 0, 75% 0, 100% 45%, 100% 100%, 25% 100%, 0 55%);
    margin-top: 0px;
}

.sponinfo {
    width: 90%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sponinfo p {
    width: 90%;
    font-size: 2rem;
    font-family: myFont, 'Wallpoet';
    margin: 0;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 1200px) {
    .Main {
        height: auto;
        margin-bottom: 50px;
    }
    .Box {
        margin: 0;
        width: 100%;
        height: auto ;
        background-color: #111111;
    }
    .spon-info-div {
        width: 100%;
        align-items: center;
    }
    .spon-first-p {
        width: 100%;
        text-align: center;
    }
    .spon-sec-p {
        width: 80%;
        max-width: 500px;
    }
    .sponinfo p {
        width: auto;
    }
}
