footer {
    scroll-snap-align: center;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 80px 0 10px;
    background-color: #95C5F4;
    background-image: url("https://res.cloudinary.com/dqrwkgoi8/image/upload/v1706947666/mepfbrmcnc9ov2ibpesv.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.footer-row {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
}

.footer-col {
    width: 50%;
}

.footer-logo {
    margin-bottom: 10px;
    color: white;
    text-decoration: none;
    font-size: 2rem;
    font-family: myFont, 'Wallpoet';
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-col p {
    color: white;
    font-family: 'Poppins', 'JetBrains Mono';
    text-align: justify;
    font-size: 1rem;
}

.footer-icons {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-icons li{
    width: 30px;
    height: 30px; 
    border-radius: 50%;
    background-color: #95C5F4;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.footer-icons li:hover {
    scale: 1.1;
    background-color: white;
    box-shadow: 0px 0px 30px 3px #95C5F4;
}

.footer-icons li a {
    font-size: 1rem;
    color: black;
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer hr {
    width: 90%;
    margin: 20px auto;
}

.bottom-footer {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    color: white;
    font-family: 'Poppins', 'JetBrains Mono';
    font-size: 1rem;
}

.bottom-footer a {
    color: white;
}

.bottom-most-footer {
    width: 90%;
    text-align: left;
    margin: 0;
    padding-bottom: 20px;
    color: white;
    font-family: 'Poppins', 'JetBrains Mono';
    font-size: 1rem;
}

@media (max-width: 700px) {
    footer {
        position: relative;
    }

    .footer-col {
        flex-basis: 100%;
    }

    .footer-col:nth-child(2),
    .footer-col:nth-child(3) {
        flex-basis: 15%;
    }
    .bottom-footer {
        flex-direction: column-reverse;
    }
    .footer-icons {
        width: 100%;
    }
    .bottom-most-footer {
        text-align: center;
    }
}   