.coursel {
    width: 100%;
    height: 100%;
}

.Chota_card {
    padding: 10px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Child_Box {
    width: 200px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.Child_Box img {
    width: 100%;
}

.coursel h6 {
    margin: 25px 0px;
    text-align: center;
    padding: 0px;
    font-size: 1rem;
    color: white;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
}