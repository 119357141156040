.parentdiv{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maindiv{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.heading{
    font-family: myFont, 'Wallpoet';
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    width: 90%;
    height: auto;
    margin: 0;
    margin-top: 50px;
    text-align: left;
}
.heading p {
    margin: 0;
    width: 63%;
}
.subheading{
    width: 80%;
    margin : 0px;
}

.lis {
    width: 100%;
    font-family: 'Poppins', 'JetBrains Mono';
}

.lis p:first-of-type {
    color: #99C7F4;
    font-size: 1.2rem;
    margin: 5px 0px;
}

.lis p:last-of-type {
    margin: 5px 0px;
    font-size: 1rem;
    color: white;
    text-align: justify;
}

@media (max-width:1200px)
{
    .parentdiv {
        background-color: #111111;
        height: auto;
    }
    .maindiv {
        height: auto;
    }
    .heading{
        width: 100%;
        text-align: center;
    }
    .heading p {
        width: 100%;
    }
    .subheading {
        width: auto;
    }
    .lis p:last-of-type {
        width: 90%;
        max-width: 500px;
    }
}