.main_card {
    width: 80vw;
    padding: 40px;
    box-sizing: border-box;
    margin: 20px;
    display: flex;
    background: #111111;
    flex-direction: row;
    /* box-shadow: inset 5px 5px 5px #344f6a,
        inset -5px -5px 15px #344f6a,
        5px 5px 15px #344f6a, -5px -5px 15px #344f6a; */
    background: #111111;
    border-radius: 15px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
}

.card__image {
    width: 50%;
    max-height: 300px;
    /* border-radius: var(--border-radius-primary); */
    object-fit: cover;
    margin-bottom: 18px;
    transition: 0.5s;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
}

.card__content {
    margin: 0px 50px;
    width: 40%;
    transition: 0.5s;
}

body .main__container .card__image:hover {
    transform: translateY(-50px);
}


.card__title {
    font-size: 2rem;
    padding: 0 4px;
    margin-left: -4px;
    font-family: myFont;
    color: #95c5f4;
}

.card__subtitle {
    font-size: 1.2rem;
    font-family: 'Poppins';
}

.card__content a {
    text-decoration: none;
    transition: 0.5s;
}

.card_buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_buttons button {
    width: 120px;
    /* height: 30px; */
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #262626;
    border: none;
    border-radius: 5px;
    color: white;
}

.card_buttons button:hover {
    background-color: white;
    color: black;
}