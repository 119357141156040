.heroinfo {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-evenly;
}

.logo-div {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.logo-div img {
    width: 70%;
    /* animation: logo 2s infinite ease-in-out; */
}

.shadow {
    width: 80px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    /* animation: shadow 2s infinite ease-in-out; */
    filter: blur(3px);
}

@keyframes logo {
    0% {
        transform: scale(1) translateY(0);
    }
    50% {
        transform: scale(1.05) translateY(-20px);
    }
}

@keyframes shadow {
    0% {
        transform: scale(1) translateX(0);
    }
    50% {
        transform: scale(0.8) translateX(20px);
    }
}

.heroinfo #heading {
    margin: 0;
    padding: 0;
    font-size: 4rem;
    font-family: myFont, 'Wallpoet', sans-serif;
    text-align: center;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heroinfo p {
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    margin: 10px 0;
}

.heroinfo #tagline {
    font-size: 1.2rem;
}

.heroinfo #information {
    font-size: 1rem;
    width: 70%;
    color: white;
    max-width: 500px;
    text-align: justify;
}

.heroinfo button {
    width: 200px;
    height: 40px;
    background-color: #95c5f4;
    border: none;
    color: #262626;
    font-size: 1rem;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    clip-path: polygon(0 0, 85% 0, 100% 40%, 100% 100%, 15% 100%, 0 60%);
}

.heroinfo button:hover {
    background-color: white;
    color: #111111;
    scale: 1.05;
}

@media (max-width: 1200px) {
    .heroinfo {
        padding-top: 50px;
        width: 100%;
        height: auto;
        position: relative;
        margin: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero-info-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo-div {
        width: 80%;
    }

    .heroinfo button {
        width: 150px;
        margin: 25px 0px;
    }

    .heroinfo #tagline {
        text-align: center;
    }
}

@media (max-width : 650px) {
    .heroinfo #heading {
        font-size: 3.5rem;
    }

    .heroinfo #tagline {
        width: 80%;
    }
}

@media (max-width : 550px) {
    .heroinfo #heading {
        font-size: 3rem;
    }
}

@media (max-width : 400px) {
    .heroinfo #heading {
        font-size: 2.5rem;
    }
}