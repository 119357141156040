.game-main {
    width: 100vw;
    height: 100vh;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-main .main-game-head {
    margin: 100px 0 50px 0;
    width: 100%;
    font-size: 4rem;
    font-family: myFont, 'Wallpoet', sans-serif;
    text-align: center;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 3;
}

.games-card-container {
    width: 80vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

@media (max-width : 1200px) { 
    .game-main .main-game-head {
        margin: 50px 0;
    }
}


.side-img {
    width: 70%;
    max-width: 500px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 2;
}

@media (max-width : 1200px) {
    .side-img {
        right: 0px;
    }
}

@media (max-width : 600px) {
    .game-main .main-game-head {
        font-size: 2.7rem;
        margin: 100px 0 0 0;
    }
}