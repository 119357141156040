.herobottom {
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-end;
}

.rnxginfo {
    width: 40%;
    height: 100%;
    background-color: transparent;
    clip-path: polygon(0 0, 90% 0, 100% 12%, 100% 100%, 17% 100%, 0 80%);
}

.rnxginfo p:first-of-type {
    margin: 0;
    padding: 0;
    font-family: myFont, 'Wallpoet', monospace;
    font-size: 2rem;
    background: linear-gradient(0deg, #2995FF, #95C5F4 50.47%, #50564F 80.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rnxginfo p:last-of-type {
    width: 80%;
    color: white;
    font-size: 0.9rem;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    text-align: justify;
    margin: 5px;
}

@media ( max-width : 1430px ) and ( min-width : 1200px ) {
    .rnxginfo p:last-of-type {
        font-size: 0.9rem;
    }
}

.rnxginfo button {
    width: 140px;
    height: 40px;
    background-color: #95c5f4;
    border: none;
    color: #262626;
    font-size: 1rem;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    clip-path: polygon(0 0, 75% 0, 100% 45%, 100% 100%, 25% 100%, 0 55%);
}

.rnxginfo button:hover {
    background-color: white;
    color: #111111;
    scale: 1.05;
}

.clock {
    width: 20%;
    height: 80%;
    font-family: 'Gugi';
    font-size: 1.7rem;
    background: linear-gradient(291deg, #121212 0%, #404040 101.05%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.gameinfo {
    width: 20%;
    height: 80%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem;
}

.gameinfo p {
    font-family: 'Gugi';
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width : 1200px) {
    .herobottom {
        position: relative;
        height: auto;
    }
    .rnxginfo {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rnxginfo p:last-of-type {
        max-width: 500px;
    }
    .rnxginfo button {
        margin: 25px 0px;
    }
    .clock {
        width: 90%;
    }
    .gameinfo {
        width: 90%;
    }
}