.rnxg-logo-div {
    position: absolute;
    top: -50px;
    left: -30px;
    width: 200px;
}

nav {
    width: 100%;
    height: 10%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
    z-index: 99999999;
}

.nav-ul-div {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

nav ul {
    width: 70%;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

nav ul li a {
    text-decoration: none;
    color: white;
    font-family: 'Gugi', sans-serif;
    font-size: 1.2rem;
}

nav ul li:last-of-type {
    display: none;
}

nav button {
    width: 200px;
    height: 30px;
    background-color: #262626;
    border: none;
    color: #b1b1b1;
    font-size: 1rem;
    font-family: 'Poppins', 'JetBrains Mono', monospace;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%);
}

nav div {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: none;
}

#circle1 {
    background-color: #262626;
}

#circle2 {
    background-color: #95c5f4;
}

.mobile {
    display: none;
    z-index: 5;
}

@media only screen and (max-width : 1415px) {
    nav button {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 9% 100%);        
    }
}

@media only screen and (max-width : 1000px) {
    nav button {
        width: 150px;
        height: 30px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 7% 100%);        
    }
}

@media only screen and (max-width : 1200px) {
    nav {
        display: none;
        width: 100vw;
        height: 100vh;
        margin: 0;
        left: auto;
        right: 0;
        background-color: #262626;
        opacity: 0.9;
        flex-direction: column;
        z-index: 9;
        position: fixed;
    }
    .nav-ul-div {
        height: 50%;
    }
    nav ul {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
        flex-direction: column;
    }
    nav div {
        width: 100%;
        height: 50%;
    }
    .nav-button-div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .circle {
        display: none;
    }
    .mobile {
        margin: 0;
        display: flex;
        width: 90%;
        height: 60px;
        justify-content: flex-end;
        align-items: center;
    }
    .mobile button {
        background-color: #262626;
        border: none;
        color: white;
        width: 50px;
        height: 35px;
        font-size: 2rem;
    }
    nav ul li:last-of-type {
        display: inline;
        position: absolute;
        top: 5%;
        right: 5%;
    }
    nav ul li:last-of-type a {
        font-size: 2.5rem;
    }
    nav ul li a {
        font-size: 1.5rem;
    }
    nav div button {
        width: 100%;
        height: 50px;
        font-size: 2rem;
    }
}

@media ( max-width : 500px ) {
    .rnxg-logo-div {
        left: -60px;
    }
}